import React, {
  createContext,
  useContext,
  useMemo,
  useState,
  useEffect,
  useCallback,
} from 'react'
import useDebounce from 'react-use/lib/useDebounce'
import produce from 'immer'

const SAVE_DELAY = 250
const STORE_KEY = 'items'

const StoreContext = createContext()

export const StoreProvider = ({ children }) => {
  const [cache, setCache] = useState({})
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    const initial = JSON.parse(window.localStorage.getItem(STORE_KEY))
    if (initial) {
      setCache(initial)
    }
    setLoading(false)
  }, [])

  const mergeData = useCallback(
    (key, data) => {
      setCache(
        produce(cache, (draft) => {
          draft[key] = { ...cache[key], ...data }
        })
      )
    },
    [cache]
  )

  const save = useCallback(() => {
    window.localStorage.setItem(STORE_KEY, JSON.stringify(cache))
  }, [cache])

  const [, cancelDebouncedSave] = useDebounce(save, SAVE_DELAY, [save])

  useEffect(() => {
    return cancelDebouncedSave
  }, [cancelDebouncedSave])

  const value = useMemo(
    () => ({
      data: cache,
      mergeData,
      loading,
    }),
    [cache, loading, mergeData]
  )

  return <StoreContext.Provider value={value}>{children}</StoreContext.Provider>
}

export function useStore() {
  const context = useContext(StoreContext)
  if (context === undefined) {
    throw new Error('useStore must be used within a StoreProvider')
  }
  return context
}
